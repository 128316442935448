<template>
    <div class="admin-index">
        <a-row :gutter="[16,16]">
            <a-col :span="24" v-if="site">
                <div class="ai-con ai-header">
                    <div class="ai-company">
                        <a-avatar size="large" :src="site.logo"></a-avatar>
                        <span class="name">{{site.web_title}}</span>
                    </div>
                    <div class="ai-commpany-edit">
                        <router-link to="/admin/siteSet/webSite">
                            <a-button type="primary">编辑资料</a-button>
                        </router-link>
                    </div>
                </div>
            </a-col>
            <a-col :span="6">
                <div class="ai-con statistics" v-if="statistics">
                    <div class="s-title">
                        <span>总用户数</span>
                        <i class="ri-information-line ri-lg ri-top"></i>
                    </div>
                    <div class="user-total">{{statistics.user}}</div>
                    <div class="user-today">
                        <!-- <span>总员工：{{ statistics.staff }}人</span>
                        <span>代理商：{{ statistics.agent }}人</span> -->
                        <span>应用：{{ statistics.all_application }}个</span>
                    </div>
                </div>
            </a-col>
            <a-col :span="6">
                <div class="ai-con statistics" v-if="statistics">
                    <div class="s-title">
                        <span>今日交易额</span>
                        <i class="ri-information-line ri-lg ri-top"></i>
                    </div>
                    <div class="user-total">{{statistics.moneyV2.dayPrice}}</div>
                    <div class="trade-compare">
                        <span>
                            周同比：
                            <i class="ri-arrow-up-s-fill ri-top" v-if="statistics.moneyV2.weekGrowth>=0" style="color:#00CC66"></i>
                            <i class="ri-arrow-down-s-fill ri-top" v-if="statistics.moneyV2.yesterdayGrowth<0" style="color:#FF6600"></i>
                            {{ statistics.moneyV2.weekGrowth}}% 
                        </span>
                        <span>日环比：
                            <i class="ri-arrow-up-s-fill ri-top" v-if="statistics.moneyV2.weekGrowth>=0" style="color:#00CC66"></i>
                            <i class="ri-arrow-down-s-fill ri-top" v-if="statistics.moneyV2.yesterdayGrowth<0" style="color:#FF6600"></i>
                            {{ statistics.moneyV2.yesterdayGrowth}}%
                        </span>
                    </div>
                </div>
            </a-col>
            <a-col :span="6">
                <div class="ai-con statistics" v-if="statistics">
                    <div class="s-title">
                        <span>交易商品占比</span>
                        <i class="ri-information-line ri-lg ri-top"></i>
                    </div>
                    <a-space>
                         <div class="goods">
                            <div><span style="color:#91cc75">●</span>土地  {{numberTo2(statistics.proportion.land_b*100)}}%</div>
                            <div><span style="color:#ee6666">●</span>商品  {{numberTo2(statistics.proportion.shop_b*100)}}%</div>
                            <div><span style="color:#5470c6">●</span>认养  {{numberTo2(statistics.proportion.adopt_b*100)}}%</div>
                            <div><span style="color:#fac858">●</span>其他  {{numberTo2(statistics.proportion.other_b*100)}}%</div>
                        </div>
                        <div id="shopChart" class="chartcycle"></div>
                    </a-space>
                </div>
            </a-col>
            <a-col :span="6">
                <div class="ai-con statistics" v-if="statistics">
                    <div class="cash-deposit">
                        <div class="cd-icon"><i class="ri-wallet-3-line ri-xl ri-top"></i></div>
                        <div class="cd-right">
                            <div class="s-title">
                                <span>商户保证金</span>
                                <i class="ri-information-line ri-lg ri-top"></i>
                            </div>
                            <div class="price">{{ statistics.shopBail.dayPrice}}</div>
                        </div>
                    </div>
                    <div class="trade-compare">
                        <span>周同比：
                            <i class="ri-arrow-up-s-fill ri-top" v-if="statistics.shopBail.weekGrowth>=0" style="color:#00CC66"></i>
                            <i class="ri-arrow-down-s-fill ri-top" v-if="statistics.shopBail.weekGrowth<0" style="color:#FF6600"></i>
                            {{ statistics.shopBail.weekGrowth}}%
                        </span>
                        <span>日环比：
                            <i class="ri-arrow-up-s-fill ri-top" v-if="statistics.shopBail.yesterdayGrowth>=0" style="color:#00CC66"></i>
                            <i class="ri-arrow-down-s-fill ri-top" v-if="statistics.shopBail.yesterdayGrowth<0" style="color:#FF6600"></i>
                            {{ statistics.shopBail.yesterdayGrowth}}%
                        </span>
                    </div>
                </div>
            </a-col>
            <a-col :span="24">
                <div class="ai-con app-con">
                    <div class="ac-title mb12">应用列表</div>
                    <a-space>
                        <com-server-select @success="e=>app.server_id = e"></com-server-select>
                        <a-input style="width: 300px" v-model:value="app.key" />
                        <a-button  @click="getProgramList(1,app.limit)" type="primary">
                            <i class="ri-search-2-line ri-btn"></i>搜索
                        </a-button>
                    </a-space>
                    <app-list :list="app.list" :pageCount="app.count" :type="'1'" @changeList="getProgramList(app.page,app.limit)"></app-list>
                    <div class="pager">
                        <a-pagination 
                            hideOnSinglePage
                            show-size-changer 
                            v-model:current="app.page" 
                            v-model:pageSize="app.limit" 
                            :total="app.count" 
                            @showSizeChange="(p,e)=>{getProgramList(app.page,e)}"
                            @change="(e)=>getProgramList(e,app.limit)"
                        />
                    </div>
                </div>  
            </a-col>
            <a-col :span="12">
                <div class="ai-con update-con">
                    <div class="verson">V {{version ? version.site.version:"1.0.1"}}</div>
                    <div class="time">{{ version ? version.site.update_time : '2021-01-01'}}更新</div>
                    <router-link to="/admin/siteSet/system_update?histroy=1">
                        <a-button type="primary" ghost>更新日志</a-button>
                    </router-link>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="ai-con log-con">
                    <a-table :pagination="false" size="small" :scroll="{ y: 200 }" row-key="id" :data-source="log.list" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'操作人',dataIndex:'user.name'},
                        {title:'说明',dataIndex:'title'},
                        {title:'APPID',dataIndex:'program.program_id'},
                        {title:'应用名称',dataIndex:'program.program_name'},
                        {title:'操作时间',dataIndex:'create_time'},
                    ]">
                    </a-table>
                    <div class="pager">
                        <a-pagination 
                            hideOnSinglePage
                            show-size-changer 
                            v-model:current="log.page" 
                            v-model:pageSize="log.limit" 
                            :total="log.count" 
                            @showSizeChange="(p,e)=>{getLog(log.page,e)}"
                            @change="(e)=>getLog(e,log.limit)"
                        />
                    </div>
                </div>
            </a-col>
        </a-row>
    </div>
</template>
<script>
import setModel from '@/api/saas/set.js'
import * as echarts from 'echarts'
import { computed, reactive, toRefs ,ref} from 'vue'
import appModel from "@/api/saas/app.js"
import appList from '@/components/admin/appList.vue';
import comServerSelect from '@/components/admin/com-server-select.vue'
export default {
    components:{
        appList,
        comServerSelect
    },
    setup() {
        const _d = reactive({
            site:null,
            statistics:null,
            shopChart:null,
            app:{
                list:[],
                page:1,
                limit:10,
                count:0,
                key:"",
                server_id:0,
                serverList:[],  //行业方案
            },
            version:null,
            log:{
                list:[],
                page:1,
                count:0,
                limit:10,
            }
        })

        const numberTo2 = computed(()=>{
            return function(num){
                return num.toFixed(0)
            }
        })
        //获取站点信息
        setModel.getWebSite((res)=>{
            _d.site = res
        })

        setModel.getStatistics((res)=>{
            _d.statistics = res
            let timer = setTimeout(() => {
                drawLine()
                clearTimeout(timer)
            }, 1000);
        })
        setModel.getCurrentVersinInfo(false,res=>{
            _d.version = res
        })
        getProgramList(1,10);
        getLog(1,10);

        function getProgramList(page,limit){
            let ser_id = _d.app.server_id[_d.app.server_id.length-1]
            appModel.getProgramList(1,page,limit,{key:_d.app.key,server_id:_d.app.server_id},(res)=>{
                _d.app.list = res.list
                _d.app.count = res.count
            })
        }
        function getLog(page,limit){
            setModel.getSaasLog(page,limit,0,(res)=>{
                _d.log.list = res.list
                _d.log.count = res.count
            })
        }
        function drawLine() {
            _d.shopChart =  echarts.init(document.getElementById('shopChart'),'walden');
            _d.shopChart.setOption({
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center',
                    show:false
                },
                series: [
                    {
                        name: '访问来源',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '12',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            {value: (_d.statistics.proportion.land_b*100).toFixed(0), name: '土地',itemStyle: { color: '#91cc75' }},
                            {value: (_d.statistics.proportion.shop_b*100).toFixed(0), name: '商品',itemStyle:{color:"#ee6666"}},
                            {value: (_d.statistics.proportion.adopt_b*100).toFixed(0), name: '认养',itemStyle:{color:"#5470c6"}},
                            {value: (_d.statistics.proportion.other_b*100).toFixed(0), name: '其他',itemStyle:{color:"#fac858"}},
                        ]
                    }
                ]
            })
        }

        return {
            ...toRefs(_d),
            numberTo2,
            getProgramList,
            getLog,
        }
    },
}
</script>
<style lang="scss" scoped>
.ai-con{
    background: #fff;
    padding: 12px;
}
.ai-header{
    display: flex;
    justify-content: space-between;

    .ai-company{
        .name{
            margin-left: 12px;
        }
    }
}
.statistics{
    height: 160px;
    .s-title{
        display: flex;
        justify-content: space-between;
        color: #999;
        font-size: 13px;
    }
    .user-total{
        font-size: 32px;
    }
    .user-today{
        font-size: 13px;
        color: #999;
        margin-top: 40px;

        span{
            margin-right: 12px;
        }
    }

    .trade-compare{
        font-size: 13px;
        color: #999;
        margin-top: 40px;

        span:last-child{
            margin-left: 24px;
        }
    }
    .goods{
        width: 85px;
        font-size: 13px;
        color: #999;
        margin-top: 12px;
        span{
            margin-right: 6px;
        }
    }

    .cash-deposit{
        display: flex;
        position: relative;
        .cd-icon{
            width: 60px;
            height: 60px;
            border-radius: 50%;
            text-align: center;
            line-height: 60px;
            color: #fff;
            background: #1890ff;
            margin-right: 12px;
        }
        .cd-right{
            width: 75%;
        }

        .price{
            font-size: 28px;
        }
    }
    .cash-deposit:after{
        content: "";
        width: 100%;
        position: absolute;
        height: 1px;
        background: #f4f4f4;
        top: 90px;
    }
}

.app-con{
    padding: 0 12px 12px 12px;
    .ac-title{
        font-weight: bold;
        font-size: 18px;
        height: 64px;
        line-height: 64px;
        border-bottom: 1px solid #f4f4f4;
    }
}

.update-con{
    text-align: center;
    height: 300px;
    padding: 90px;
    .verson{
        font-size: 22px;
        font-weight: bold;
    }
    .time{
        font-size: 12px;
        color: #999;
        margin: 6px 0;
    }
}
.log-con{
    height: 300px;
}

.chartcycle{
    width: 120px; 
    height: 120px;
}
</style>